* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
  
html {
    font-size: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
body {
    background-color: #f9fafb;
}
  
a {
    text-decoration: none;
}
  